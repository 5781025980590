/* eslint-disable import/prefer-default-export */
import errorPageCodes from './errorPageCodes';
import { makeEl } from './utils/makeEl';

function getErrorCode(el) {
  const errorCodeInput = el.dataset.mosaicErrorCode;

  return !Object.prototype.hasOwnProperty.call(errorPageCodes, errorCodeInput)
    ? '400'
    : errorCodeInput;
}

export function errorPage(el) {
  const errorCode = getErrorCode(el);
  const { actionLink, description, header, statusLink } =
    errorPageCodes[errorCode];

  // .ontainer
  const container = makeEl({ className: 'error-container' });
  el.append(container);

  // Error Code Heading
  const errorHeading = makeEl({ type: 'h2', className: 'error-code' });
  const errorHeadingBefore = makeEl({
    type: 'span',
    className: 'before',
    text: errorCode,
  });
  const errorHeadingAfter = makeEl({
    type: 'span',
    className: 'after',
    text: errorCode,
  });
  errorHeadingAfter.setAttribute('aria-hidden', true);
  errorHeading.append(errorHeadingBefore, errorHeadingAfter);
  container.append(errorHeading);

  // Content article
  const article = makeEl({
    type: 'article',
    className: 'error-content d-flex flex-column gap-1',
  });
  container.append(article);

  // Header
  const pageHeader = makeEl({
    type: 'h1',
    className: 'error-content-header',
    text: header,
  });

  const sections = makeEl({
    type: 'div',
    className: 'd-flex flex-column gap-3',
  });
  article.append(pageHeader, sections);

  // Description
  const descriptionHeader = makeEl({
    type: 'h4',
    className: 'error-content-description',
    text: description,
  });
  sections.append(description);

  // Status
  if (statusLink) {
    const status = makeEl({
      type: 'h4',
      className: 'error-content-description',
    });

    status.innerHTML = statusLink;
    sections.append(status);
  }

  // Action
  const linkP = makeEl({ type: 'p' });
  const linkAction = makeEl({ type: 'a', text: actionLink });
  linkAction.setAttribute('href', el.dataset.mosaicErrorAction || '/');
  linkP.append(linkAction);
  sections.append(linkP);
}

const errorPageEl = document.querySelector(
  '.sovos-error-page[data-mosaic-error-code]',
);

if (errorPageEl) {
  errorPage(errorPageEl);
}
