/* eslint-disable import/prefer-default-export */
import Sidenav from '../pro/sidenav';
import EventHandler from '../mdb/dom/event-handler';
import SelectorEngine from '../mdb/dom/selector-engine';

export const SELECTOR_DRAWER = '.drawer';
export const SELECTOR_TOGGLE = '[data-mdb-toggle="drawer"]';

const DRAWER_OPTIONS = {
  right: true,
  width: 384,
};

export function addDrawer(drawer) {
  let instance = Sidenav.getInstance(drawer, DRAWER_OPTIONS);
  if (!instance) {
    instance = new Sidenav(drawer, DRAWER_OPTIONS);
  }

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape' && instance.isVisible) {
      instance.toggle();
    }
  });

  return instance;
}

EventHandler.on(document, 'click', SELECTOR_TOGGLE, Sidenav.toggleSidenav());

SelectorEngine.find(SELECTOR_DRAWER).forEach((sidenav) => {
  const drawerInstance = addDrawer(sidenav);

  // instantiate drawer toggle buttons
  Array.from(document.querySelectorAll(SELECTOR_TOGGLE)).forEach((trigger) => {
    const targetId = trigger.dataset.mdbTarget.slice(1); // remove the '#'
    const drawerId = sidenav.id;

    if (targetId === drawerId) {
      trigger.addEventListener('click', () => drawerInstance.toggle());
    }
  });
});
