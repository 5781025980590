/* eslint-disable import/prefer-default-export */

// Toggle Nested Navigation items
export function toggleNav(e, element) {
  e.preventDefault();
  e.stopPropagation();
  const nestedList = element.nextElementSibling;
  element.classList.toggle('open');
  nestedList.classList.toggle('open');
}

const nestedLinks = Array.from(
  document.querySelectorAll('nav a[aria-haspopup="true"]')
);

// Attach event listener to all nested nav links
if (nestedLinks.length > 0) {
  nestedLinks.forEach((link) =>
    link.addEventListener('click', (e) => {
      toggleNav(e, link);
    })
  );
}

document.body.addEventListener('click', (event) => {
  const openLinks = document.querySelectorAll(
    'nav.nav-bar__primary-nav a[aria-haspopup="true"].open'
  );

  const openMenus = document.querySelectorAll(
    'nav.nav-bar__primary-nav ul[aria-label="submenu"].open'
  );

  if (openMenus.length < 1) return;

  const clickOutside = (el) => {
    if (!el.contains(event.target)) {
      el.classList.remove('open');
    }
  };

  openLinks.forEach((link) => clickOutside(link));
  openMenus.forEach((menu) => clickOutside(menu));
});
