// Main entry for mosaic-css javascript
import * as mdb from './mdb.pro';
import { FilledSelect } from './mosaic/filledSelect';
import {
  addDrawer,
  addFilledInput,
  addFilledSelect,
  toggleNav,
} from './mosaic/index';

window.mdb = {
  ...mdb,
  FilledSelect,
  addDrawer,
  addFilledInput,
  addFilledSelect,
  toggleNav,
};

export * from './mdb.pro';

export { addDrawer, addFilledInput, addFilledSelect, toggleNav };
