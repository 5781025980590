/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import Select from '../pro/select';
import Manipulator from '../mdb/dom/manipulator';

export const SELECTOR_SELECT = '.select';

export function addSelect(element, config) {
  let instance = Select.getInstance(element, config);
  if (!instance) {
    instance = new Select(element, config);
  }

  return instance;
}

// instantiate select
export function addAllSelects() {
  Array.from(document.querySelectorAll(SELECTOR_SELECT)).forEach((element) => {
    addSelect(element);
  });
}

addAllSelects();
