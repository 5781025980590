import Input from '../free/input';
import EventHandler from '../mdb/dom/event-handler';

export const SELECTOR_FILLED = '.form-filled';
export const SELECTOR_FILLED_INPUT = `${SELECTOR_FILLED} input`;

export function addFilledInput(element, config) {
  new Input(element, config);
}

EventHandler.on(
  document,
  'focus',
  SELECTOR_FILLED_INPUT,
  Input.activate(new Input()),
);
EventHandler.on(
  document,
  'input',
  SELECTOR_FILLED_INPUT,
  Input.activate(new Input()),
);
EventHandler.on(
  document,
  'blur',
  SELECTOR_FILLED_INPUT,
  Input.deactivate(new Input()),
);

// instantiate inputs
export function addAllFilledInputs() {
  Array.from(document.querySelectorAll(SELECTOR_FILLED)).forEach((element) => {
    addFilledInput(element);
  });
}

addAllFilledInputs();
