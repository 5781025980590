/* eslint-disable import/prefer-default-export */
export function updateDateToggleButton(button) {
  if (!button) return;

  const oldToggle = button.querySelector('.datepicker-toggle-icon');
  if (oldToggle) {
    oldToggle.parentNode.removeChild(oldToggle);
  }

  const newButton = document.createElement('span');
  newButton.classList.add('material-icons-outlined', 'datepicker-toggle-icon');
  const text = document.createTextNode('event');
  newButton.append(text);
  button.append(newButton);
}

Array.from(document.querySelectorAll('.datepicker-toggle-button')).forEach(
  (button) => updateDateToggleButton(button),
);
