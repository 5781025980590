const errorPageCodes = {
  400: {
    actionLink: 'Go to the Home Page',
    description: "We don't understand this request",
    header: 'Bad request',
  },
  401: {
    actionLink: 'Log in',
    description:
      "You don't have permission to view this page. Log in to your account and if that doesn't work, contact your admin.",
    header: 'Unauthorized',
  },
  403: {
    actionLink: 'Go to the Home Page',
    description:
      "You don't have permission to view this page. Contact your admin for access.",
    header: 'Forbidden',
  },
  404: {
    actionLink: 'Go to the Home Page',
    description:
      "We couldn't find this page. What you're looking for has either been moved, deleted, or the link might be broken.",
    header: 'Page not found',
  },
  408: {
    actionLink: 'Refresh page',
    description:
      "The server is taking too long to respond. Make sure you're connected to the internet, then try refreshing the page.",
    header: 'Request timeout',
  },
  500: {
    actionLink: 'Go to the Home Page',
    description:
      "Something is not working on our end but it's temporary. Refresh this page or come back a bit later.",
    header: 'Internal server error',
  },
  501: {
    actionLink: 'Go to the Home Page',
    description:
      "We're unable to display this content. Refresh the page or try again from the home page.",
    header: 'Not implemented',
  },
  502: {
    actionLink: 'Go to the Home Page',
    description:
      "Something is not working on our end but it's temporary. Refresh this page or come back a bit later.",
    header: 'Service temporarily overloaded',
    statusLink:
      'You can also check <a href="https://status.sovos.com" target="_blank"/>status.sovos.com</a> for updates.',
  },
  503: {
    actionLink: 'Go to the Home Page',
    description:
      "Something is not working on our end but it's temporary. Refresh this page or come back a bit later.",
    header: 'Service unavailable',
    statusLink:
      'You can also check <a href="https://status.sovos.com" target="_blank"/>status.sovos.com</a> for updates.',
  },
};

export default errorPageCodes;
