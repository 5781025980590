/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import Select from '../pro/select';
import Manipulator from '../mdb/dom/manipulator';

export const SELECTOR_SELECT_FILLED = '.select-filled';

export class FilledSelect extends Select {
  constructor(element, config) {
    super(element, config);

    const formElement = this._wrapper.querySelector('.form-outline');
    Manipulator.removeClass(formElement, 'form-outline');
    Manipulator.addClass(formElement, 'form-filled');
  }
}

export function addFilledSelect(element, config) {
  let instance = FilledSelect.getInstance(element, config);
  if (!instance) {
    instance = new FilledSelect(element, config);
  }

  return instance;
}

// instantiate select
export function addAllFilledSelects() {
  Array.from(document.querySelectorAll(SELECTOR_SELECT_FILLED)).forEach(
    (element) => {
      addFilledSelect(element);
    },
  );
}

addAllFilledSelects();
