/* eslint-disable import/prefer-default-export */
import { makeEl } from './utils/makeEl';
import { breakpoints } from './globals';

const gridLayout = document.querySelector('.mosaic-layout');
const navIsCollapsed = sessionStorage.getItem('navIsCollapsed');

const navLinks = document.querySelectorAll(
  '.main-nav-links > .main-nav-links__item > .main-nav-links__link'
);
const nestedLinks = document.querySelectorAll(
  'nav.main-nav a[aria-haspopup="true"]'
);

function renderIconPlaceholders() {
  if (navLinks) {
    navLinks.forEach((link) => {
      const icon = link.querySelector('.material-icons');
      const iLetter = link.querySelector('i');
      if (icon || iLetter) return;

      const letter = link.querySelector('span').textContent[0];

      const i = makeEl({
        type: 'i',
        className: 'icon-placeholder',
        text: letter,
      });

      link.append(i);
    });
  }
}

function createSubMenuDropdown(link, i) {
  const fragment = document.createDocumentFragment();
  const container = makeEl({ className: 'dropdown' });
  fragment.append(container);

  const trigger = link.cloneNode(true);
  trigger.removeAttribute('data-mosaic-toggle');
  trigger.dataset.mdbToggle = 'dropdown';
  trigger.id = `nestedMenu__0${i}`;
  trigger.setAttribute('aria-expanded', 'false');
  container.append(trigger);

  const nestedList = link.nextElementSibling.cloneNode(true);
  nestedList.setAttribute('aria-labelledby', `nestedMenu__0${i}`);
  nestedList.classList.remove('main-nav-links--nested');
  nestedList.classList.add('dropdown-menu');

  const nestedListItems = nestedList.querySelectorAll('.main-nav-links__item');
  nestedListItems.forEach((item) => {
    item.classList.remove('main-nav-links__item');
    const nestedLink = item.querySelector('a');
    nestedLink.classList.remove('main-nav-links__link');
    nestedLink.classList.add('dropdown-item');
  });

  container.append(nestedList);

  return fragment;
}

function renderDropdownMenus() {
  nestedLinks.forEach((link, i) => {
    const listItem = link.parentNode;
    if (listItem.querySelector('.dropdown')) return;
    listItem.append(createSubMenuDropdown(link, i));
  });
}

function setCollapsed() {
  if (gridLayout) {
    gridLayout.dataset.collapsed = 'true';
  }
  renderDropdownMenus();
  renderIconPlaceholders();
  sessionStorage.setItem('navIsCollapsed', 'true');
}

function removeCollapsed() {
  if (gridLayout) {
    gridLayout.dataset.collapsed = 'false';
  }

  sessionStorage.setItem('navIsCollapsed', 'false');

  // remove iconPlaceholders
  const iconPlaceholders = document.querySelectorAll('.icon-placeholder');
  if (iconPlaceholders.length) {
    iconPlaceholders.forEach((i) => i.parentNode.removeChild(i));
  }

  // remove dropdowns
  if (nestedLinks) {
    nestedLinks.forEach((link) => {
      const dropdown = link.querySelector('.dropdown');
      if (dropdown) {
        link.removeChild(dropdown);
      }
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  if (navIsCollapsed === 'true') {
    setCollapsed();
  }
});

window.addEventListener('resize', () => {
  const collapsedSet = sessionStorage.getItem('collapsedSet');
  if (collapsedSet === 'true') return;

  if (window.innerWidth < breakpoints.values.md) {
    setCollapsed();
  } else {
    removeCollapsed();
  }
});

export function toggleNavCollapse() {
  const isCollapsed = gridLayout.dataset.collapsed;

  if (isCollapsed === 'true') {
    sessionStorage.setItem('collapsedSet', false);
    removeCollapsed();
  } else {
    sessionStorage.setItem('collapsedSet', true);
    setCollapsed();
  }
}

const collapseBtn = document.querySelector('.nav-footer__collapseButton');

if (collapseBtn) {
  collapseBtn.addEventListener('click', toggleNavCollapse);
}
